import React, { useState, useEffect, useCallback } from 'react'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { isMobile } from 'react-device-detect'

import Button from './Button' 
import type { MediaEntryFragment } from '../graphql/__generated__'
import SortableMediaViewer from './SortableMediaViewer'

import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
} from '@dnd-kit/sortable'
import { classNames } from '../utils/classes'

type MediaListingProps = {
  segments: MediaEntryFragment[]
  musicSegments: MediaEntryFragment[]
  allMedia: MediaEntryFragment[]
  designId: string
  onRemove(ids: string[]): void
  onReorder(ids: string[]): void
  onOpenUpload(): void
}

export const mediaAgeMin = (m: MediaEntryFragment) => ((+new Date) - +new Date(m.createdAt)) / (1000 * 60)

export default function MediaListing(props: MediaListingProps) {
  const [segments, setSegments] = useState<MediaEntryFragment[]>(props.segments)
  const [showRemoveForMediaId, setShowRemoveForMediaId] = useState<string | undefined>(undefined)
  const setShowRemove = useCallback((mediaId: string, showRemove: boolean) => {
    setShowRemoveForMediaId(showRemove ? mediaId : undefined)
  }, [showRemoveForMediaId])

  useEffect(() => {
    setSegments(props.segments)
  }, [props.segments])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      // TODO: instead of increasing delta, calculate the spacing between each media element horizontally and veritically
      //       this is a "nice to have" since likely not many people use keyboard navigation...but, it would make reordering
      //       with keyboard arrows seemlessly reorder your media without slowly moving the images around.
      //
      // coordinateGetter: (event, args) => {
      //   const {currentCoordinates} = args
      //   const delta = 175
      //
      //   switch (event.code) {
      //     case 'ArrowRight':
      //       event.preventDefault()
      //
      //       return {
      //         ...currentCoordinates,
      //         x: currentCoordinates.x + delta,
      //       }
      //     case 'ArrowLeft':
      //       event.preventDefault()
      //
      //       return {
      //         ...currentCoordinates,
      //         x: currentCoordinates.x - delta,
      //       }
      //     case 'ArrowDown':
      //       event.preventDefault()
      //
      //       return {
      //         ...currentCoordinates,
      //         y: currentCoordinates.y + delta,
      //       }
      //     case 'ArrowUp':
      //       event.preventDefault()
      //
      //       return {
      //         ...currentCoordinates,
      //         y: currentCoordinates.y - delta,
      //       }
      //   }
      // },
    })
  );

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (!active || !over || !segments) {
      return
    }

    if (active.id !== over.id) {
      const oldIndex = segments.findIndex(m => m.id === active.id)
      const newIndex = segments.findIndex(m => m.id === over.id)
      if (oldIndex === undefined || newIndex === undefined) {
        throw new Error('unable to find position of reordered media')
      }

      const newSegments = arrayMove(segments, oldIndex, newIndex)
      setSegments(newSegments)

      props.onReorder([
        ...newSegments.map(m => m.id),
        // include the music media to avoid losing it when reordering non-music
        ...props.musicSegments.map(m => m.id),
      ])
    }
  }

  const brokenMedia = segments.filter(m =>
    mediaAgeMin(m) > 5 && (!m.mezzanineLocation || !m.signedThumbnailLocation)
  )

  const removeBrokenFiles = () => {
    props.onRemove(brokenMedia.map(m => m.id))
  }

  return <>
    { brokenMedia.length ? <div className="mb-4 p-4 bg-red-100 flex space-x-4">
      <div className="space-y-4">
        <p className="font-bold">
          Some of your media appears to not have loaded properly. We apologize for the inconvenience.
        </p>
        
        <p>
          Unfortunately you may need to remove and reupload the media for your book to be previewed
          or produced.
        </p>
      </div>

      <Button
        onClick={ removeBrokenFiles }
        type="danger">Remove Broken Files</Button>
    </div> : <></>
    }
      
    <DndContext sensors={ sensors } collisionDetection={ closestCorners } onDragEnd={ handleDragEnd }>
      <SortableContext items={ segments?.map(m => m.id) ?? [] }>
        <div className={ classNames(...[
          'flex flex-wrap md:-mx-2 md:gap-y-4',
          'justify-center md:justify-start',
        ]) }>
          { segments?.map(media => <div key={ media.id } className="md:w-1/5 m-4 md:m-0 min-h-[10rem]">
            <SortableMediaViewer
              media={ media }
              designId={ props.designId }
              showRemove={ showRemoveForMediaId === media.id }
              setShowRemove={ setShowRemove }
              onRemove={ () => props.onRemove([media.id]) }
              allMedia={ props.allMedia }
            />
          </div>) }
          <div key="add" className="w-full md:w-auto md:flex-grow">
            <a
              onClick={ props.onOpenUpload }
              className={ classNames(...[
                'flex flex-col h-[10rem] my-4 md:my-0 md:mx-2 justify-center',
                'bg-gray-50 rounded shadow cursor-pointer',
              ]) }
            >
              <span className="text-center text-gray-500 text-xs font-bold">
                <PhotoIcon className="m-auto block w-12 h-12" />
                <span className="w-full block mt-2">
                  { isMobile
                    ? "Add Media"
                    : "Drop Photos, Videos and Music Files Here"
                  }</span>
              </span>
            </a>
          </div>
        </div>
      </SortableContext>
    </DndContext>
  </>
}
